import { createContext, useContext, useReducer } from 'react';

export enum InvoiceLayoutActionTypes {
    SetCreateInvoice,
}

type Action = { type: InvoiceLayoutActionTypes.SetCreateInvoice; payload: boolean };

type State = {
    createInvoice: boolean;
};

type Dispatch = (action: Action) => void;
type InvoiceLayoutProviderProps = { children: React.ReactNode };

const InvoiceLayoutStateContext = createContext<State | undefined>(undefined);
const InvoiceLayoutProviderContext = createContext<Dispatch | undefined>(undefined);

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case InvoiceLayoutActionTypes.SetCreateInvoice:
            return {
                ...state,
                createInvoice: action.payload,
            };

        default:
            throw new Error('Unknown action');
    }
};

export const InvoiceLayoutProvider = ({ children }: InvoiceLayoutProviderProps) => {
    const [state, dispatch] = useReducer(reducer, {
        createInvoice: false,
    });

    return (
        <InvoiceLayoutStateContext.Provider value={state}>
            <InvoiceLayoutProviderContext.Provider value={dispatch}>{children}</InvoiceLayoutProviderContext.Provider>
        </InvoiceLayoutStateContext.Provider>
    );
};

export const useInvoiceLayoutState = () => {
    const context = useContext(InvoiceLayoutStateContext);

    if (!context) {
        throw new Error('useLayoutState must be used within a LayoutProvider');
    }

    return context;
};

export const useInvoiceLayoutDispatch = () => {
    const context = useContext(InvoiceLayoutProviderContext);

    if (!context) {
        throw new Error('useLayoutDispatch must be used within a LayoutProvider');
    }

    return context;
};

export const setCreateInvoice = (payload: boolean): Action => ({
    type: InvoiceLayoutActionTypes.SetCreateInvoice,
    payload,
});
